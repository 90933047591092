// external
import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { change } from 'redux-form';
import ValidatedForm from '../../../components/molecules/ValidatedForm';
import Config from '../../utils/config';

// internal
import totalLoanValidator from '../../validators/totalLoan';
import stripLeadingZero from '../../utils/strip-leading-zero';
import stripNonNumeric from '../../utils/strip-non-numeric';
import style from './style';
import LanguageContext from '../../../context/language';

const Wrapper = styled.div`
  && {
    margin-top: ${(props) => (props.loanCalculatorOmalaina ? '0' : style.totalAmountMarginTop)};
  }
`;

const TotalAmountInput = ({
  whitelist,
  data,
  showErrors,
  formType,
}) => {
  const config = Config.getValues();
  const dispatch = useDispatch();

  const { lang } = useContext(LanguageContext);
  const changeField = (...args) => change('application', ...args);
  const totalAmount = useSelector((state) => state.form.application.values.totalAmount);

  useEffect(() => {
    dispatch(changeField('consolidationAmount', totalAmount));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount]);

  return (
    <Wrapper
      loanCalculatorOmalaina={data?.calculator}
    >
      <ValidatedForm.Slider
        name={data.name}
        placeholder={data.placeholder}
        label={data.label}
        suffix={data.suffix}
        showButtons={data.showButtons}
        englishSuffix={lang === 'en'}
        type="tel"
        parse={(inputValue) => stripLeadingZero(stripNonNumeric(inputValue))}
        maxLength={String(config.LOAN.UP_TO).length + 1} // Take space between numbers into account
        validate={totalLoanValidator}
        showErrors={showErrors}
        tooltip={data.tooltip}
        formType={formType}
        totalAmountField
        sliderConfig={{
          range: {
            min: config.LOAN.FROM,
            max: config.LOAN.UP_TO,
          },
          step: config.LOAN.STEP,
        }}
        withTextInput
        labelOutside
        whitelist={whitelist}
        fieldStyle={data.fieldStyle}
        loanCalculatorStyle={data.loanCalculatorStyle}
      />
    </Wrapper>
  );
};

TotalAmountInput.propTypes = {
  data: PropTypes.shape(),
  whitelist: PropTypes.bool,
  showErrors: PropTypes.bool,
  formType: PropTypes.string,
};

TotalAmountInput.defaultProps = {
  data: {},
  whitelist: false,
  showErrors: false,
  formType: '',
};

export default TotalAmountInput;
