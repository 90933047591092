import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import styled from 'styled-components';
import messageDescriptorSchema from '../schemas/message-descriptor';
import Alert from '../../components/atoms/Alert';
import logger from './logger';

import { region } from '../localizations/current-locale';
import { dynamicBrand } from './brand';

import localeData from '../localizations/locale-data';
import { useEmailSubmissionMutation } from '../store/reducers/api-call';
import devices from '../styles/Devices';

const { EmailSubmissionForm } = localeData.COMPONENTS;

let messages;
import(`./text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const ErrorBoundaryStyled = styled.div`
  && {
    background: var(--link-color);
    align-content: center;
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
  }
`;

const Headline = styled.h2`
  && {
    color: var(--white);
    flex: 1 100%;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const ErrorBoundaryAlert = styled.div`
  && {
    margin: 0 auto;
    text-align: center;
    padding: 0 1rem;
    
    button{
      margin-top: 1rem;
      background: var(--white);
      padding: 0.5rem 3rem;
    }

    @media ${devices.upFromLargeDesktop} {
      width: 50%;
    }
  }
`;

const ErrorBoundary = (props) => {
  const [emailSubmissionSubmitError, setEmailSubmissionSubmitError] = useState(false);

  const formState = useSelector((state) => state.form.application);
  const [emailSubmissionSubmit, result] = useEmailSubmissionMutation();

  const {
    children,
    headline,
    intl,
  } = props;

  const sendPhoneNumber = (phoneNumber) => {
    logger.info('User requested to be called back after site-wide crash');

    emailSubmissionSubmit({ phoneNumber })
      .catch((error) => {
        logger.error('User could not request to be called back after site-wide crash', { error });
        setEmailSubmissionSubmitError(true);
        throw new SubmissionError({ _error: 'Could not send phoneNumber' });
      });
  };

  const ErrorFallbackComponent = () => {
    const hasPhoneNumber = !!formState?.values?.applicant?.phoneNumber;
    const errorMessage = hasPhoneNumber
      ? intl.formatMessage(messages.defaultErrorMessageWithPhoneNumber, {
        i: (chunks) => <i>{chunks}</i>,
        customerPhoneNumber: formState.values.applicant.phoneNumber,
      })
      : intl.formatMessage(messages.defaultErrorMessage);

    return (
      <ErrorBoundaryStyled>
        <Headline>
          <h1>
            {headline
              ? intl.formatMessage(messages.headline)
              : intl.formatMessage(messages.defaultErrorHeadline)}
          </h1>
        </Headline>
        <ErrorBoundaryAlert>
          <Alert
            display={errorMessage}
            type="neutral"
          >
            {(hasPhoneNumber && (
              <>
                <button
                  type="button"
                  className={classNames(
                    'c-button',
                    'c-button--blue',
                    'c-application-form__button--call-me',
                    { 'c-button--loading': result?.isLoading && !emailSubmissionSubmitError },
                  )}
                  disabled={result?.isLoading || result?.isSuccess}
                  onClick={() => sendPhoneNumber(formState?.values?.applicant?.phoneNumber)}
                >
                  <FormattedMessage
                    id="error-boundary.applicationForm.emailSubmission"
                    defaultMessage="Email Submission"
                    description="'Email Submission'-button text on submit error in the form"
                  />
                </button>
                {result?.isSuccess && (
                  <Alert
                    display={intl.formatMessage(messages.emailSubmissionSuccessMessage)}
                    type="info"
                  />
                )}
                {emailSubmissionSubmitError && (
                  <Alert
                    display={intl.formatMessage(messages.emailSubmissionErrorMessage)}
                    type="error"
                  />
                )}
              </>
            ))
              || (
                <EmailSubmissionForm
                  form="emailSubmission"
                  formState={result}
                  inputPlaceholder={intl.formatMessage(messages.emailSubmissionInputPlaceholder)}
                  buttonLabel={intl.formatMessage(messages.emailSubmissionButtonLabel)}
                  successMessage={intl.formatMessage(messages.emailSubmissionSuccessMessage)}
                  onSubmit={({ phoneNumber }) => sendPhoneNumber(phoneNumber)}
                />
              )}
          </Alert>
        </ErrorBoundaryAlert>
      </ErrorBoundaryStyled>
    );
  };

  const getChildren = () => children;

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallbackComponent}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {getChildren()}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  emailSubmissionState: PropTypes.shape({
    submitting: PropTypes.bool,
    submitSuccess: PropTypes.bool,
    submitted: PropTypes.bool,
  }),
  children: PropTypes.node,
  headline: PropTypes.oneOfType([
    PropTypes.string,
    messageDescriptorSchema,
  ]),
  intl: PropTypes.shape().isRequired,
};

ErrorBoundary.defaultProps = {
  emailSubmissionState: null,
  children: '',
  headline: '',
};

export default (injectIntl(ErrorBoundary));
