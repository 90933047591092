import LOAN_LIMITS from '../shared/loan-limits';
import { addThousandSeparator } from '../../../../utils/formatters/add-thousand-separator';

const TOTALAMOUNTSELECT = [];
let i;

for (i = 500; i <= 3000; i += 100) {
  TOTALAMOUNTSELECT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
}

for (i = 3500; i <= 5500; i += 500) {
  TOTALAMOUNTSELECT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
}

for (i = 6000; i <= LOAN_LIMITS.UP_TO; i += 1000) {
  TOTALAMOUNTSELECT.push({ label: `${addThousandSeparator(parseInt(i, 10))} €`, value: i });
}

export default TOTALAMOUNTSELECT;
