import textResource from '../../../../utils/text-resources/omalaina/FI/messages';

const EDUCATION_TYPES = [
  {
    label: textResource.educationTypePrimarySchool,
    value: 'primarySchool',
  },
  {
    label: textResource.educationTypeHighSchool,
    value: 'highSchool',
  },
  {
    label: textResource.educationTypeCollege,
    value: 'college',
  },
  {
    label: textResource.educationTypeBachelor,
    value: 'bachelor',
  },
  {
    label: textResource.educationTypeMasters,
    value: 'masters',
  },
];
export default EDUCATION_TYPES;
