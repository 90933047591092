export default {
  selectedButtonBackground: 'var(--white)',
  wrapperMarginRight: '1.2rem',
  selectedBorderColor: 'var(--teal)',
  nonSelectedBackgroundColor: '#f5f5f5',
  toggleTabletLayout: 'repeat(2, 1fr)',
  boxShadow: 'none',
  labelWidth: '90%',
  buttonGroupFontWeight: '500',
  checkMarkColor: 'var(--teal)',
};
