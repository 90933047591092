import textResource from '../../../../utils/text-resources/omalaina/FI/messages';

const MARITAL_STATUS = [
  {
    label: textResource.civilStatusSingle,
    value: 'single',
  },
  {
    label: textResource.civilStatusPartner,
    value: 'partner',
  },
  {
    label: textResource.civilStatusMarried,
    value: 'married',
  },
  {
    label: textResource.civilStatusDivorced,
    value: 'divorced',
  },
  {
    label: textResource.civilStatusWidow,
    value: 'widow',
  },
];

export default MARITAL_STATUS;
