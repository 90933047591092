import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import devices from '../../../../../js/styles/Devices';
import messageDescriptor from '../../../../../js/schemas/message-descriptor';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';
import Tooltips from '../../../../utils/tooltips';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    background: none;
    border: none;
    position: relative;
    width:auto;
     @media ${devices.upFromTablet} {
      width: ${(props) => (props.calculator ? 'auto' : '14rem')};
     }

    @media ${devices.upFromSmallDesktop} {
      width: ${(props) => {
    if (props.calculator) {
      return 'auto';
    } if (props.lead) {
      return '100%';
    }
    return '15.125rem';
  }};
    }
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 0 0.5rem;
  }
`;

const Label = styled.label`
  && {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
  }
`;

const Input = styled.div`
  && {
    position: relative;
    border: 0;
    border: '1px solid';
    border-color: ${(props) => props.borderColor};
    outline: none;
    background: var(--white);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    box-sizing: border-box;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;

    // validated
    ${(props) => props.valid && props.validated && css`
      border: 2px solid var(--teal);
    `}

    // not validated
    ${(props) => (props.validated && props.valid === false) && css`
      border:  2px solid var(--red);
    `}

    // default
    ${(props) => props.valid === null && css`
      border: 2px solid var(--white);
    `}
  }
`;

const InputSelect = styled.select`
  && {
    border: none;
    outline: none;
    align-items: center;
    height: 3rem;
    width: 100%;
    background: var(--white);
    padding: 0.6em;
    font-smooth: antialiased; // Non-standard: This feature is non-standard and is not on a standards track. Remove
    box-sizing: border-box;
    font-family: inherit;
    font-size: 1rem;
    color: var(--base-brown);
    appearance: none;
    background-image: url(https://fi-omalaina-production-cms-uploadbucket-hnf2k3pbeict.s3.amazonaws.com/uploads/2023/03/Omalaina_dropdown.svg);
    background-position: 95%;
    background-repeat: no-repeat;
    background-size: 1.1rem;
    font-weight: bold;
    ${(props) => props.placeholder && css`
    color: var--placeholder-grey);
  `}

  ${(props) => props.valid && css`
    color: var(--base-brown);
  `}
  }
`;

const Select = ({
  id,
  name,
  compact,
  labelInside,
  label,
  multiple,
  validated,
  valid,
  className,
  options,
  tooltip,
  sorted,
  tooltipLink,
  intl: { formatMessage },
  override,
  margin,
  placeholder,
  lead,
  calculator,
  ...rest
}) => {
  const selectId = id || `select--${name || ''}--${label.replace(/\s/, '-')}`;

  const getLabel = (option) => {
    if (typeof option.label === 'string') return option.label;

    return formatMessage(option.label);
  };

  const getKey = (option) => {
    if (typeof option.label === 'string') return option.label;

    return option.label.id;
  };

  const selectOption = (option) => (
    <option
      key={option.value}
      value={option.value}
      disabled={option.disabled}
    >
      {getLabel(option)}
    </option>
  );

  switch (sorted) {
    case 'A-Z':
      options.sort((a, b) => {
        if (formatMessage(a.label) < formatMessage(b.label)) { return -1; }
        if (formatMessage(a.label) > formatMessage(b.label)) { return 1; }
        return 0;
      });
      // add placeholder back on top
      // eslint-disable-next-line no-nested-ternary
      options.sort((x, y) => (x.value === '' ? -1 : y.value === '' ? 1 : 0));
      break;
    default:
  }

  const selectOptions = options.map((option) => (
    option.subOptions
      ? (
        <optgroup
          key={getKey(option)}
          label={getLabel(option)}
        >
          {option.subOptions.map((subOption) => selectOption(subOption))}
        </optgroup>
      )
      : selectOption(option)
  ));

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') { tooltipObject.text = formatMessage(tooltip.text); }

  return (
    <Wrapper
      lead={lead}
      margin={margin}
      calculator={calculator}
    >
      {label && label.id && (
      <LabelWrapper>
        <Label
          htmlFor={selectId}
        >
          {formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
      </LabelWrapper>
      )}

      <Input
        validated={validated}
        valid={valid}
        lead={lead}
      >
        <InputSelect
          placeholder={placeholder}
          lead={lead}
          valid={valid}
          id={selectId}
          name={name}
          multiple={multiple}
          {...rest}
        >
          {selectOptions}
        </InputSelect>
      </Input>

    </Wrapper>
  );
};

const optionSchema = PropTypes.shape({
  label: PropTypes.oneOfType([
    PropTypes.string,
    messageDescriptor,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabled: PropTypes.bool,
});

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  margin: PropTypes.string,
  compact: PropTypes.bool,
  labelInside: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  sorted: PropTypes.string,
  validated: PropTypes.bool,
  lead: PropTypes.bool,
  valid: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      optionSchema,
      PropTypes.shape({
        subOptions: PropTypes.arrayOf(optionSchema),
      }),
    ]),
  ),
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  intl: intlShape.isRequired,
  placeholder: PropTypes.shape(),
  override: PropTypes.shape({
    input: PropTypes.shape({
      labelClassName: PropTypes.string,
    }),
  }),
  calculator: PropTypes.bool,
};

Select.defaultProps = {
  id: null,
  name: null,
  compact: false,
  labelInside: false,
  label: '',
  margin: '',
  sorted: '',
  multiple: false,
  validated: false,
  valid: null,
  options: [],
  className: '',
  tooltip: '',
  tooltipLink: '',
  lead: false,
  placeholder: {},
  override: {
    input: {},
  },
  calculator: false,
};

export default injectIntl(Select);
