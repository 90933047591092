// external
import { region } from '../localizations/current-locale';
import { dynamicBrand } from '../utils/brand';

// internal
import { valid, invalid } from '../utils/validators/resolutions';
import stripDelimiter from '../utils/strip-delimiter-from-currency';

let messages;

import(`../utils/text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const validateOtherIncomeBeforeTax = (input = '', ...props) => {
  const cleanedInput = stripDelimiter(input);

  if (!/\S+/.test(cleanedInput || '')) {
    return (props[2] === 'applicant.otherMonthlyIncomeBeforeTax'
      ? invalid(messages.otherIncomeBeforeTaxRequired)
      : invalid(messages.coApplicantOtherIncomeBeforeTaxRequired));
  }

  if (!/^(-?\d+)$/.test(cleanedInput)) {
    return (props[2] === 'applicant.otherMonthlyIncomeBeforeTax'
      ? invalid(messages.otherIncomeOnlyNumbers)
      : invalid(messages.coApplicantOtherIncomeOnlyNumbers));
  }

  return valid();
};

export default validateOtherIncomeBeforeTax;
