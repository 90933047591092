const MERGE_LOAN_FORM_DEFAULT_VALUES = {
  totalAmount: 20000,
  repaymentTime: 15,
  administrationFee: 0,
  consolidationAmount: 20000,
  merge: false,
  applicant: {
    dependants: '0',
    politicallyExposedPerson: false,
    otherIncome: false,
    currentLoanList: [],
    currentLoans: {
      numCurrentLoans: '0',
    },
  },
  coApplicant: {
    dependants: '0',
    politicallyExposedPerson: false,
    otherIncome: false,
    currentLoanList: [],
    currentLoans: {
      numCurrentLoans: '0',
    },
  },
  acceptNewsletter: false,
};

const LOAN_CALCULATOR_DEFAULT_VALUES = {
  totalAmount: '20000',
  interestRate: 6.56,
  repaymentTime: 15,
  setUpFee: '30',
  aviaFee: '10',
};

export {
  MERGE_LOAN_FORM_DEFAULT_VALUES,
  LOAN_CALCULATOR_DEFAULT_VALUES,
};
