export default {
  ctaButtonBackground: 'var(--harmony-dark-red)',
  buttonBorderRadius: '1rem',
  buttonBorderRadiusLead: '1rem',
  buttonPadding: '2rem',
  boxShadow: 'none',
  opacity: '0.4',
  fontWeight: 'bold',
  transform: 'uppercase',
  ctaButtonColor: 'var(--white)',
};
