const LINK_URLS = {
  WEBSITE: 'https://www.omalaina.fi/',
  AUTOMATED_PAYMENT_LINK: '#',
  INSURANCE_TERMS_LINK: '#',
  PRESALE_INFO_LINK: '#',
  ECONOMY_HELPLINE: '#',
  TERMS_LINK: 'https://www.omalaina.fi/kayttajaehdot/',
  INTEGRITY_POLICY_LINK: `${process.env.GATSBY_FRONTEND_BASE_URL}/tietosuoja/`,
  COMPENSATION_LEVELS_EXAMPLE_LINK: '#',
  FORM_DATA_CONSENT_LINK: 'https://www.omalaina.fi/rekisteriseloste/',
};

export default LINK_URLS;

