export default {
  ctaButtonBackground: 'var(--base-brown)',
  buttonBorderRadius: '1rem',
  buttonBorderRadiusLead: '4rem',
  buttonPadding: '2rem',
  boxShadow: 'none',
  buttonWidth: '100%',
  checkMarkTextFontSize: '0.8rem',
  acceptNewsLetterMargin: '0',
  storeValueMaxWidth: '5rem',
  breakHeight: '1rem',
  buttonTextColor: 'var(--white)',
};
