import textResource from '../../../../utils/text-resources/omalaina/FI/messages';

const MILITARY_TYPES = [
  {
    label: textResource.militaryTypeExecuted,
    value: 'success',
  },
  {
    label: textResource.militaryTypeNotCompleted,
    value: 'performing',
  },
  {
    label: textResource.militaryTypeReleased,
    value: 'exempt',
  },
  {
    label: textResource.militaryTypeNotApplicable,
    value: 'notAMilitaryServant',
  },
];
export default MILITARY_TYPES;
