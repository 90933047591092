export default {
  titleTextAlign: 'left',
  titleFontSize: '2rem',
  subtitleColor: 'var( --harmony-dark-red)',
  subtitleFontSize: '1rem',
  subtitleFontWeight: 'bold',
  formTitleColor: 'var(--base-brown)',
  titleMarginLeft: '1.45rem',
  mobileFontsize: '1.5rem',
  formTitlePadding: '2.5rem 1rem 0',
  formTitlePaddingDownTablet: '1.5rem 0 0',
  subtitlePadding: '0 2.5rem',
  subtitlePaddingMobile: '0 0.5rem',
  mobileTitleAlign: 'center',
  desktopTitleFontWeight: 'bold',
  userAgreementDataPolicyPadding: '0.9rem',
};
