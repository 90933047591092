import textResource from '../../../../utils/text-resources/omalaina/FI/messages';

const EMPLOYMENT_TYPES = [
  {
    label: textResource.employmentTypePermanent,
    value: 'untilFurtherNotice',
  },
  {
    label: textResource.employmentTypeTemporary,
    value: 'temporary',
  },
  {
    label: textResource.employmentTypePartTime,
    value: 'partTime',
  },
  {
    label: textResource.employmentTypeSelfEmployed,
    value: 'selfEmployed',
  },
  {
    label: textResource.employmentTypeRetired,
    value: 'retired',
  },
  {
    label: textResource.employmentTypeStudent,
    value: 'student',
  },
  {
    label: textResource.employmentTypeUnemployed,
    value: 'unemployed',
  },
  {
    label: textResource.employmentTypeDisabilityPension,
    value: 'disabilityPension',
  },
];

export default EMPLOYMENT_TYPES;
