/* eslint-disable max-len */
import { feesCost } from '../../../../validators';

import textResource from '../../../../utils/text-resources/omalaina/FI/messages';
import TOTALAMOUNTSELECT from '../../../../localizations/config/FI/omalaina/totalAmount-select';
import { validateRequiredButtonGroup } from '../../../../validators/requiredButtonGroup';

const tooltip = (text) => {
  const temp = {
    trigger: 'focus',
    text,
    width: 'fill',
    position: 'right',
  };
  return temp;
};

const data = {
  general: {},
  links: {},
  backgroundColor: 'var(--orange-light)',
  gridColumnGap: '1rem',
  slides: [
    {
      showBackButton: false,
      fields: [
        {
          component: 'ValidatedForm',

          form: 'Select',
          props: {
            name: 'totalAmount',
            calculator: true,
            label: textResource.applicationLoanAmountLabel,
            labelOutside: true,
            tooltip: tooltip(textResource.applicationLoanAmountTooltip),
            hasValidateFunction: true,
            validate: validateRequiredButtonGroup,
            options: TOTALAMOUNTSELECT,
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'InterestRateInput',
          props: {
            name: 'interestRate',
            label: textResource.loanCalculatorInterestRateLabel,
            placeholder: textResource.loanCalculatorInterestRatePlaceholder,
            suffix: textResource.loanCalculatorInterestRateSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.loanCalculatorInterestRateTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'RepaymentTimeInput',
          props: {
            name: 'repaymentTime',
            label: textResource.loanCalculatorRepaymentTimeLabel,
            placeholder: textResource.loanCalculatorRepaymentTimePlaceholder,
            suffix: textResource.loanCalculatorRepaymentTimeSuffix,
            whitelist: true,
            tooltip: tooltip(textResource.loanCalculatorRepaymentTimeTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'setUpFee',
            isNumber: true,
            label: textResource.loanCalculatorSetUpFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorSetUpFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorSetUpFeeTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'ValidatedForm',
          form: 'FeesInput',
          props: {
            name: 'aviaFee',
            isNumber: true,
            label: textResource.loanCalculatorAviaFeeLabel,
            validate: feesCost,
            placeholder: textResource.loanCalculatorAviaFeePlaceholder,
            hasValidateFunction: true,
            suffix: textResource.loanCalculatorCurrencySuffix,
            type: 'tel',
            tooltip: tooltip(textResource.loanCalculatorAviaFeeTooltip),
            layout: {
              start: 1,
              end: 3,
            },
          },
        },
        {
          component: 'SummarySection',
          props: {
            name: 'summarySection',
            loanCalculatorAmortizationTypeLabel: textResource.loanCalculatorAmortizationTypeLabel,
            loanCalculatorAmortizationTypeAnnuity: textResource.loanCalculatorAmortizationTypeAnnuity,
            loanCalculatorAmortizationTypeStraight: textResource.loanCalculatorAmortizationTypeStraight,
            loanCalculatorTotalCostsForLoan: textResource.loanCalculatorTotalCostsForLoan,
            loanCalculatorTotalCostsAndInterestAndFeesForLoan: textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoan,
            loanCalculatorMonthlyCost: textResource.loanCalculatorMonthlyCost,
            loanCalculatorMonthlyCostOverviewButtonText: textResource.loanCalculatorMonthlyCostOverviewButtonText,
            loanCalculatorSummaryTablePageDescription: textResource.loanCalculatorSummaryTablePageDescription,
            loanCalculatorEffectiveInterest: textResource.loanCalculatorEffectiveInterest,
            loanCalculatorSummaryTableColumnMonth: textResource.loanCalculatorSummaryTableColumnMonth,
            loanCalculatorSummaryTableColumnDebtBeforeAmortization: textResource.loanCalculatorSummaryTableColumnDebtBeforeAmortization,
            loanCalculatorSummaryTableColumnAmortization: textResource.loanCalculatorSummaryTableColumnAmortization,
            loanCalculatorSummaryTableColumnInterestCost: textResource.loanCalculatorSummaryTableColumnInterestCost,
            loanCalculatorSummaryTableColumnFeeCost: textResource.loanCalculatorSummaryTableColumnFeeCost,
            loanCalculatorSummaryTableColumnToPay: textResource.loanCalculatorSummaryTableColumnToPay,
            loanCalculatorMonthlyCostMonthOne: textResource.loanCalculatorMonthlyCostMonthOne,
            currencySuffix: textResource.loanCalculatorCurrencySuffix,
            loanCalculatorInterest: textResource.loanCalculatorInterest,
            loanCalculatorAmortizationTypeTooltip: tooltip(textResource.loanCalculatorAmortizationTypeTooltip),
            loanCalculatorTotalCostsForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsForLoanTooltip),
            loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: tooltip(textResource.loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip),
            loanCalculatorMonthlyCostTooltip: tooltip(textResource.loanCalculatorMonthlyCostTooltip),
            loanCalculatorInterestRateTooltip: tooltip(textResource.loanCalculatorInterestRateTooltip),
            layout: {
              start: 3,
              end: 5,
              rowStart: 1,
              rowEnd: 2,
            },
          },
        },
        {
          component: 'Teleporter',
          props: {
            title: textResource.loanCalculatorNextSlideButtonHeading,
            subtitle: textResource.loanCalculatorNextSlideButtonSubHeading,
            targetSlideIndex: 1,
            label: textResource.loanCalculatorNextSlideButton,
            ctaBtnStyle: true,
            centered: true,
            destinationPath: '/hae-lainaa/',
            layout: {
              start: 3,
              end: 5,
              rowStart: 2,
            },
          },
        },
      ],
    },
  ],
};

export default data;
